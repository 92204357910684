import styled from 'styled-components'

export const ServiceMeetingItem = styled.div`
  display: flex;
  flex-wrap: wrap;

  &.highlight .date {
    font-weight: 700;
  }

  section.info {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    p {
      flex: 1;
    }

    .location,
    .assigned {
      text-align: right;
    }

    .date {
      text-transform: capitalize;
    }

    @media screen {
      .assigned {
        width: 100%;
        flex: none;
      }
    }
  }

  @media screen {
    & + & {
      margin-top: 1rem;
    }
  }

  aside {
    display: flex;

    margin-left: 1rem;

    a {
      align-self: center;
    }

    @media print {
      display: none;
    }
  }
`

export default ServiceMeetingItem
