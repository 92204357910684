import { DatePickerAriaComponent as DatePickerAria } from '../../DatePicker/DatePickerAriaComponent'

import {
  ClipboardEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useRef,
  useState,
} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IServiceMeeting } from '../../../declarations/interfaces.d'

import {
  Protip,
  StyledItemAddButton,
  StyledServiceMeeting,
  StyledServiceMeetingForm,
} from '../../../views/Upload/styles'

import { capitalizeFirstLetter } from '../../../utils/string'
import { useButton } from '@react-aria/button'
import Modal from '../../Modal'
import { OverlayContainer } from '@react-aria/overlays'
import {
  endOfWeek,
  getLocalTimeZone,
  DateFormatter,
  parseAbsoluteToLocal,
  parseDate,
  startOfWeek,
  toCalendarDate,
  now,
  ZonedDateTime,
  parseTime,
  toCalendarDateTime,
} from '@internationalized/date'
import { isSameWeek } from '../../../utils/date'
import { useDateFormatter } from 'react-aria'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

// const StyledServiceMeetingsGrid = styled.div`
//   display: grid;
//   grid-template-columns: auto 1fr 1fr auto;
//   grid-gap: 1rem;
// `

const StyledServiceMeetingsTable = styled.table`
  width: 100%;
  margin-top: 0.5rem;
  border-collapse: collapse;

  thead {
    font-weight: 600;

    th {
      padding: 0.25rem 0.5rem;
      text-align: left;
    }
  }

  td {
    padding: 0.25rem 0.5rem;
    /* border: 1px solid #ccc; */
  }

  tbody tr {
    /* background-color: white; */

    @media (prefers-color-scheme: dark) {
      /* color: white; */
      /* background-color: #333; */
    }
  }
`

export function getServiceMeetingId(serviceMeeting: IServiceMeeting) {
  return (
    serviceMeeting.timestamp + serviceMeeting.assigned + serviceMeeting.location
  )
}

interface ServiceMeetingItemProps {
  serviceMeeting: IServiceMeeting
  onDelete(id: string): void
  onSave(id: string, serviceMeeting: IServiceMeeting): void
}

export function ServiceMeetingItem({
  serviceMeeting,
  onDelete,
  onSave,
}: ServiceMeetingItemProps) {
  const { t } = useTranslation('views')

  const id = getServiceMeetingId(serviceMeeting)
  const [editing, setEditing] = useState(false)

  const formatter = useDateFormatter({ dateStyle: 'full', timeStyle: 'short' })
  const [timestampNew, setTimestampNew] = useState(
    parseAbsoluteToLocal(serviceMeeting.timestamp),
  )
  const [location, setLocation] = useState(serviceMeeting.location)
  const [assigned, setAssigned] = useState(serviceMeeting.assigned)

  const canAddServiceMeeting =
    Boolean(location.length) && Boolean(assigned.length)

  function cancelEditing() {
    setEditing(false)

    setTimestampNew(parseAbsoluteToLocal(serviceMeeting.timestamp))
    setAssigned(serviceMeeting.assigned)
  }

  function editServiceMeeting(event: FormEvent<ServiceMeetingsFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const fieldValues = Object.fromEntries(formData.entries()) // { order, timestamp, assigned, location }
    const { assigned, location } = fieldValues

    setEditing(false)

    onSave(id, {
      timestamp: timestampNew.toAbsoluteString(),
      assigned: assigned.toString(),
      location: location.toString(),
    })
  }

  // if (editing) {
  //   return (
  //     <form onSubmit={editServiceMeeting}>
  //       <StyledServiceMeetingForm>
  //         <header>
  //           <FontAwesomeIcon icon={['far', 'pencil-alt']} />
  //           <p>Redigerar möte för tjänst…</p>
  //         </header>
  //         <DatePickerAria
  //           id='timestamp-new'
  //           label='Datum och tid'
  //           value={timestampNew}
  //           onChange={dateValue => setTimestampNew(dateValue as ZonedDateTime)}
  //           hideTimeZone
  //           hourCycle={24}
  //         />
  //         <p>
  //           <small>{formatter.format(timestampNew.toDate())}</small>
  //         </p>
  //         <label htmlFor='location'>Plats</label>
  //         <input
  //           type='text'
  //           id='location'
  //           name='location'
  //           placeholder='Ange en plats…'
  //           value={location}
  //           onChange={e => {
  //             setLocation(e.target.value)
  //           }}
  //           required
  //         />
  //         <label htmlFor='assigned'>Ansvarig</label>
  //         <input
  //           type='text'
  //           id='assigned'
  //           name='assigned'
  //           placeholder='Ange ett namn…'
  //           value={assigned}
  //           onChange={e => {
  //             setAssigned(e.target.value)
  //           }}
  //           required
  //         />
  //       </StyledServiceMeetingForm>
  //       <footer className='form-footer'>
  //         <div className='buttons'>
  //           <button
  //             type='button'
  //             className='btn btn-danger btn-icon'
  //             onClick={() => {
  //               onDelete(id)
  //             }}
  //           >
  //             <FontAwesomeIcon icon={['far', 'trash-alt']} />
  //           </button>
  //           <button
  //             type='button'
  //             className='btn btn-default'
  //             onClick={() => {
  //               cancelEditing()
  //             }}
  //           >
  //             Avbryt
  //           </button>
  //           <button
  //             type='submit'
  //             className='btn btn-primary'
  //             disabled={!canAddServiceMeeting}
  //           >
  //             Spara
  //           </button>
  //         </div>
  //       </footer>
  //     </form>
  //   )
  // }

  return (
    <>
      <tr>
        <td className='timestamp'>
          {capitalizeFirstLetter(
            new Date(serviceMeeting.timestamp).toLocaleDateString('sv-SE', {
              month: 'short',
              weekday: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }),
          )}
        </td>
        <td>{serviceMeeting.assigned}</td>
        <td>{serviceMeeting.location}</td>
        <td>
          <button
            type='button'
            // className='action-button'
            className='btn btn-default btn-icon'
            onClick={() => {
              setEditing(true)
            }}
          >
            <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
          </button>
        </td>
      </tr>
      {editing ? (
        <OverlayContainer>
          <Modal
            title='Redigera möte för tjänst'
            isOpen
            onClose={() => setEditing(false)}
            isDismissable
          >
            <form onSubmit={editServiceMeeting}>
              <StyledServiceMeetingForm>
                <header>
                  <FontAwesomeIcon icon={['far', 'pencil-alt']} />
                  <p>Redigerar möte för tjänst…</p>
                </header>
                <DatePickerAria
                  id='timestamp-new'
                  name='timestamp-new'
                  label='Datum och tid'
                  value={timestampNew}
                  onChange={dateValue =>
                    setTimestampNew(dateValue as ZonedDateTime)
                  }
                  hideTimeZone
                  hourCycle={24}
                />
                <p>
                  <small>{formatter.format(timestampNew.toDate())}</small>
                </p>
                <label htmlFor='location'>{t('other.location')}</label>
                <input
                  type='text'
                  id='location'
                  name='location'
                  list='sm-places'
                  placeholder='Ange en plats…'
                  value={location}
                  onChange={e => {
                    setLocation(e.target.value)
                  }}
                  required
                />
                <label htmlFor='assigned'>{t('other.assigned')}</label>
                <input
                  type='text'
                  id='assigned'
                  name='assigned'
                  list='sm-names'
                  placeholder='Ange ett namn…'
                  value={assigned}
                  onChange={e => {
                    setAssigned(e.target.value)
                  }}
                  required
                />
              </StyledServiceMeetingForm>
              <footer className='form-footer'>
                <div className='buttons'>
                  <button
                    type='button'
                    className='btn btn-danger btn-icon'
                    onClick={() => {
                      onDelete(id)
                    }}
                  >
                    <FontAwesomeIcon icon={['far', 'trash-alt']} />
                  </button>
                  <button
                    type='button'
                    className='btn btn-default'
                    onClick={() => {
                      cancelEditing()
                    }}
                  >
                    Avbryt
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={!canAddServiceMeeting}
                  >
                    Spara
                  </button>
                </div>
              </footer>
            </form>
          </Modal>
        </OverlayContainer>
      ) : null}
    </>
  )

  return (
    <StyledServiceMeeting>
      <p className='text'>
        <time className='timestamp' dateTime={serviceMeeting.timestamp}>
          {capitalizeFirstLetter(
            new Date(serviceMeeting.timestamp).toLocaleDateString('sv-SE', {
              month: 'short',
              weekday: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }),
          )}
        </time>
        <span className='by'>av </span>
        <span className='assigned'>{serviceMeeting.assigned}</span>
        <span className='at'> på </span>
        <span className='location'>{serviceMeeting.location}</span>
      </p>
      <button
        type='button'
        className='action-button'
        onClick={() => {
          setEditing(true)
        }}
      >
        <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
      </button>
    </StyledServiceMeeting>
  )
}

interface FormElements extends HTMLFormControlsCollection {
  order: HTMLInputElement
  timestamp: HTMLInputElement
  location: HTMLInputElement
  assigned: HTMLInputElement
}
interface ServiceMeetingsFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

interface ServiceMeetingsViewProps {
  serviceMeetings: IServiceMeeting[]
  setServiceMeetings: Dispatch<SetStateAction<IServiceMeeting[]>>
}

export default function ServiceMeetingsView({
  serviceMeetings,
  setServiceMeetings,
}: ServiceMeetingsViewProps) {
  const [adding, setAdding] = useState(false)
  const [isClearModalOpen, setIsClearModalOpen] = useState(false)

  const formatter = useDateFormatter({ dateStyle: 'full', timeStyle: 'short' })
  const [timestamp, setTimestamp] = useState(
    now(getLocalTimeZone()).set({ second: 0, millisecond: 0 }),
  )

  const months = Array.from(
    new Set(serviceMeetings.map(sm => sm.timestamp.substring(0, 7))),
  )

  const closeClearModalButtonRef = useRef()
  const clearServiceMeetingsButtonRef = useRef()

  const { buttonProps: closeClearModalButtonProps } = useButton(
    {
      onPress: () => setIsClearModalOpen(false),
    },
    closeClearModalButtonRef,
  )

  const { buttonProps: clearServiceMeetingsButtonProps } = useButton(
    {
      onPress: () => setIsClearModalOpen(true),
    },
    clearServiceMeetingsButtonRef,
  )

  function addServiceMeeting(ev: FormEvent<ServiceMeetingsFormElement>) {
    ev.preventDefault()

    const formData = new FormData(ev.currentTarget)
    const { assigned, location } = Object.fromEntries(formData.entries())

    if (assigned.toString().length === 0) {
      return
    }
    if (location.toString().length === 0) {
      return
    }

    const newServiceMeetings = Array.from(serviceMeetings)
    newServiceMeetings.push({
      timestamp: timestamp.toAbsoluteString(),
      location: location.toString(),
      assigned: assigned.toString(),
    })
    newServiceMeetings.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))

    setServiceMeetings(newServiceMeetings)
    ev.currentTarget.elements.assigned.value = ''
    ev.currentTarget.elements.timestamp?.focus()
  }

  function updateServiceMeeting(
    id: string,
    { timestamp, assigned, location }: IServiceMeeting,
  ) {
    const newServiceMeetings = Array.from(serviceMeetings)

    const currentIndex = newServiceMeetings.findIndex(
      sm => getServiceMeetingId(sm) === id,
    )

    newServiceMeetings.splice(currentIndex, 1)

    newServiceMeetings.push({
      timestamp: new Date(timestamp).toISOString(),
      assigned: assigned,
      location: location,
    })
    newServiceMeetings.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))

    setServiceMeetings(newServiceMeetings)
  }

  function deleteServiceMeeting(id: string) {
    const newServiceMeetings = Array.from(serviceMeetings)

    const index = newServiceMeetings.findIndex(
      sm => getServiceMeetingId(sm) === id,
    )
    newServiceMeetings.splice(index, 1)

    setServiceMeetings(newServiceMeetings)
  }

  function onPaste(ev: ClipboardEvent<HTMLElement>) {
    const data = ev.clipboardData

    if (data.types.includes('text/plain')) {
      const textData = data.getData('text/plain')

      // Clipboard data contains hidden characters from a table, like Excel
      if (textData.includes('\u0009')) {
        const formattedData = textData
          .split('\r\n')
          .map(str => str.split('\u0009'))

        const newServiceMeetings = Array.from(serviceMeetings)
        let duplicates = 0

        try {
          for (const serviceMeeting of formattedData) {
            const [assigned, location, date, time] = serviceMeeting

            if (serviceMeeting.length !== 4) {
              throw new Error(
                4 -
                  serviceMeeting.length +
                  ` kolumn(er) saknas.\n\n1. Ansvarig: ${
                    assigned === undefined ? 'SAKNAS' : assigned
                  }\n2. Plats: ${
                    location === undefined ? 'SAKNAS' : location
                  }\n3. Datum: ${
                    date === undefined ? 'SAKNAS' : date
                  }\n4. Tid: ${time === undefined ? 'SAKNAS' : time}`,
              )
            }

            const dateCal = parseDate(date)
            const timeCal = parseTime(time)
            const dateTime = toCalendarDateTime(dateCal, timeCal)
            const timestamp = dateTime.toDate(getLocalTimeZone()).toISOString()

            if (
              serviceMeetings.findIndex(
                sm =>
                  sm.assigned === assigned &&
                  sm.location === location &&
                  parseAbsoluteToLocal(sm.timestamp).compare(
                    parseAbsoluteToLocal(timestamp),
                  ) === 0,
              ) === -1
            ) {
              newServiceMeetings.push({
                timestamp,
                assigned,
                location,
              })
            } else {
              duplicates += 1
            }
          }
        } catch (error) {
          if (
            (error.message as string)?.includes('Invalid ISO 8601 date string')
          ) {
            toast.error(
              <p>
                <strong>
                  Felaktigt format på datum för möten för tjänst:{' '}
                  {error.message.substring(
                    'Invalid ISO 8601 date string: '.length,
                  )}
                </strong>
                <br />
                <br />
                Följande ordning gäller:
                <br />
                1. Ansvarig
                <br />
                2. Plats
                <br />
                3. Datum
                <br />
                4. Tid
              </p>,
              {
                duration: 8000,
              },
            )
          } else if (
            (error.message as string)?.includes('Invalid ISO 8601 time string')
          ) {
            toast.error(
              <p>
                <strong>
                  Felaktigt format på klockslag för möten för tjänst:{' '}
                  {error.message.substring(
                    'Invalid ISO 8601 date string: '.length,
                  )}
                </strong>
                <br />
                <br />
                Följande ordning gäller:
                <br />
                1. Ansvarig
                <br />
                2. Plats
                <br />
                3. Datum
                <br />
                4. Tid
              </p>,
              {
                duration: 8000,
              },
            )
          } else {
            toast.error(
              <p>
                <strong>Misslyckades att klistra in möten för tjänst: </strong>
                {error.message}
              </p>,
              { duration: 8000 },
            )
            console.error('Failed to parse service meetings on paste', error)
          }
        }

        const diff = newServiceMeetings.length - serviceMeetings.length

        if (diff > 0) {
          newServiceMeetings.sort((a, b) =>
            a.timestamp > b.timestamp ? 1 : -1,
          )

          toast.success(
            `Klistrade in ${
              newServiceMeetings.length - serviceMeetings.length
            } nya möten för tjänst.${
              duplicates > 0 ? `\n\n${duplicates} dubletter.` : ''
            }`,
          )

          setServiceMeetings(newServiceMeetings)
        } else if (duplicates > 0) {
          toast('De inklistrade mötena för tjänst finns redan i listan.')
        }
      }
    }
  }

  return (
    <section className='form service-meeting-form' onPaste={onPaste}>
      <h1>Möten för tjänst</h1>
      <div className='grid'>
        <div>
          <datalist id='sm-places'>
            {Array.from(new Set(serviceMeetings.map(sm => sm.location))).map(
              place => (
                <option key={place} value={place} />
              ),
            )}
          </datalist>
          <datalist id='sm-names'>
            {Array.from(new Set(serviceMeetings.map(sm => sm.assigned))).map(
              name => (
                <option key={name} value={name} />
              ),
            )}
          </datalist>
          {adding ? (
            <form onSubmit={addServiceMeeting}>
              <StyledServiceMeetingForm>
                <header>
                  <FontAwesomeIcon icon={['far', 'plus']} />
                  <p>Nytt möte för tjänst…</p>
                </header>
                <DatePickerAria
                  label='Datum och tid'
                  granularity='minute'
                  id='timestamp'
                  name='timestamp'
                  hideTimeZone
                  hourCycle={24}
                  value={timestamp}
                  onChange={dateValue =>
                    setTimestamp(dateValue as ZonedDateTime)
                  }
                />
                <p>
                  <small>{formatter.format(timestamp.toDate())}</small>
                </p>
                <label htmlFor='location'>Plats</label>
                <input
                  type='text'
                  id='location'
                  name='location'
                  list='sm-places'
                  placeholder='Ange en plats…'
                  required
                />
                <label htmlFor='assigned'>Ansvarig</label>
                <input
                  type='text'
                  id='assigned'
                  name='assigned'
                  list='sm-names'
                  placeholder='Ange ett namn…'
                  required
                />
              </StyledServiceMeetingForm>
              <footer className='form-footer'>
                <div className='buttons'>
                  <button
                    type='button'
                    className='btn btn-default'
                    onClick={ev => {
                      setAdding(false)

                      if (
                        ev.currentTarget.form &&
                        ev.currentTarget.form.elements.namedItem(
                          'assigned',
                        ) instanceof Element
                      ) {
                        ;(
                          ev.currentTarget.form.elements.namedItem(
                            'assigned',
                          ) as HTMLInputElement
                        ).value = ''
                      }
                    }}
                  >
                    Avbryt
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    // disabled={!canAddServiceMeeting}
                  >
                    Spara
                  </button>
                </div>
              </footer>
            </form>
          ) : (
            <StyledItemAddButton
              as='button'
              onClick={() => {
                setAdding(true)
              }}
            >
              <header>
                <FontAwesomeIcon icon={['far', 'plus']} />
                <span>Nytt möte för tjänst…</span>
              </header>
            </StyledItemAddButton>
          )}
          {serviceMeetings.length === 0 ? (
            <p style={{ marginTop: '1rem' }}>Inga möten för tjänst ännu.</p>
          ) : (
            months.map(month => {
              const sms = serviceMeetings.filter(sm =>
                sm.timestamp.startsWith(month),
              )
              const weeks = Array.from(
                new Set(
                  sms.map(sm =>
                    startOfWeek(
                      toCalendarDate(parseAbsoluteToLocal(sm.timestamp)),
                      'sv-SE',
                    ).toString(),
                  ),
                ),
              )

              return (
                <div key={month}>
                  <h3 className='sm-month'>
                    {capitalizeFirstLetter(
                      new Date(month + '-01').toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'long',
                      }),
                    )}
                  </h3>
                  {weeks.map(weekStartString => {
                    const weekSms = sms.filter(sm =>
                      isSameWeek(sm.timestamp, parseDate(weekStartString)),
                    )

                    const weekStart = new DateFormatter('sv-SE', {
                      day: 'numeric',
                      weekday: 'short',
                      month: 'short',
                    }).format(
                      startOfWeek(parseDate(weekStartString), 'sv-SE').toDate(
                        getLocalTimeZone(),
                      ),
                    )
                    const weekEnd = new DateFormatter('sv-SE', {
                      day: 'numeric',
                      weekday: 'short',
                      month: 'short',
                    }).format(
                      endOfWeek(parseDate(weekStartString), 'sv-SE').toDate(
                        getLocalTimeZone(),
                      ),
                    )

                    return (
                      <div key={weekStartString.toString()}>
                        <h4 className='sm-week'>
                          {capitalizeFirstLetter(weekStart)}–{weekEnd}
                        </h4>
                        <StyledServiceMeetingsTable>
                          <thead>
                            <tr>
                              <th>Datum/tid</th>
                              <th>Ansvarig</th>
                              <th>Plats</th>
                              <th></th>
                            </tr>
                          </thead>
                          {/* <div>Datum/tid</div>
                          <div>Ansvarig</div>
                          <div>Plats</div>
                          <div></div> */}
                          <tbody>
                            {weekSms.map(serviceMeeting => (
                              <ServiceMeetingItem
                                key={getServiceMeetingId(serviceMeeting)}
                                serviceMeeting={serviceMeeting}
                                onSave={updateServiceMeeting}
                                onDelete={deleteServiceMeeting}
                              />
                            ))}
                          </tbody>
                        </StyledServiceMeetingsTable>
                      </div>
                    )
                  })}
                </div>
              )
            })
          )}
        </div>
        <div>
          <Protip>
            <strong>Tips!</strong>
            <p>Du kan kopiera och klistra in från Excel.</p>
            <ol>
              <li>Markera de celler som du vill importera</li>
              <li>
                Kopiera (<kbd>Ctrl/Cmd + C</kbd>)
              </li>
              <li>Tryck någonstans inuti den här rutan</li>
              <li>
                Klistra in (<kbd>Ctrl/Cmd + V</kbd>)
              </li>
            </ol>
            <p>Följande tabellstruktur gäller:</p>
            <table>
              <thead>
                <tr>
                  <td>Ansvarig</td>
                  <td>Plats</td>
                  <td>Datum</td>
                  <td>Tid</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>En Person</td>
                  <td>En Plats</td>
                  <td>{new Date().toISOString().substring(0, 10)}</td>
                  <td>11:00</td>
                </tr>
              </tbody>
            </table>
          </Protip>
          {serviceMeetings.length > 0 && (
            <button
              type='button'
              className='btn btn-danger'
              style={{ marginTop: '1rem' }}
              {...clearServiceMeetingsButtonProps}
              ref={clearServiceMeetingsButtonRef}
            >
              Rensa alla möten för tjänst
            </button>
          )}
        </div>
      </div>

      {isClearModalOpen ? (
        <OverlayContainer>
          <Modal
            title='Är du säker?'
            isOpen
            onClose={() => setIsClearModalOpen(false)}
            isDismissable
          >
            <form
              onSubmit={ev => {
                ev.preventDefault()

                setServiceMeetings([])
                setIsClearModalOpen(false)
              }}
            >
              <p>
                Vill du verkligen rensa alla möten för tjänst? Alla dina
                ändringar kommer försvinna.
              </p>
              <div className='buttons'>
                <button type='submit' className='btn btn-danger'>
                  Ja, rensa möten för tjänst
                </button>
                <button
                  type='button'
                  className='btn btn-default'
                  {...closeClearModalButtonProps}
                  ref={closeClearModalButtonRef}
                >
                  Nej, avbryt!
                </button>
              </div>
            </form>
          </Modal>
        </OverlayContainer>
      ) : null}
    </section>
  )
}
